/* You can add global styles to this file, and also import other style files */

@font-face {
    font-family: 'Rubik-Italic';
    src: url('assets/font/static/Rubik-Italic.ttf');
}

/** Sets zomm level to layout **/

html {
    zoom: 100%;
}


/* Sets login page size as view size */

.login-page {
    min-height: 100vh !important;
    background-image: url(assets/images/back_pev.png) !important;
    background-size: cover !important;
    height: 100% !important;
    overflow: hidden;
}

.login-page-wop {
    background-color: #fffef2;
    margin: 0;
}

fffef2

/* Estilo padrão da imagem */
.imagem-login {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
}

/* Media query para ocultar a imagem quando a altura for maior que a largura */
@media screen and (orientation: portrait) {
    .imagem-login {
        display: none;
    }
}

.align-items-center{
    display: flex;
    align-items: center;
}

.login-logo a,
.register-logo a {
    color: #ffffff !important
}


/* Remove Overlay over Image Crop */

.modal-dialog .overlay {
    display: none !important;
}


/* Fix select2 size to be responsive */

.select2 {
    max-width: 100% !important;
}


/* Flag Input Phone */

.iti__flag {
    background-image: url(".\..\node_modules\intl-tel-input\build\img\flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
    .iti__flag {
        background-image: url(".\..\node_modules\intl-tel-input\build\img\flags@2x.png");
    }
}

.content-wrapper {
    padding-bottom: 1rem;
}

.cursor-pointer {
    cursor: pointer;
}

.color_red {
    color: red;
}

.color_green {
    color: #37b13c;
}

.color_white {
    color: #ffffff !important
}

table th,
table td {
    /* display: table-cell; */
    vertical-align: middle !important;
}

h1.loading {
    text-align: center;
    line-height: 75vh;
}

div .bemvindotext {
    text-align: center;
    padding-top: 25vh;
}

.thead_fixed {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #fff;
}